<template>
  <div class="container login_container p-3">
    <div class="row justify-content-around">
      <div class="col-5 d-none d-lg-block d-xl-block">
        <div class="left_box ">
          <img src="@/assets/login/login-background.png" style="width: 100%;" />
        </div>
      </div>
      <div class="col-10 col-lg-5 col-xl-5">
        <div class="right_box" v-if="!showCode && !showForgetPwd" style="margin-top: 2rem;">
          <b-alert :show="showAlert" variant="success">{{ successMsg }}</b-alert>
          <b-alert :show="showErrorAlert" variant="danger">{{ errorMsg }}</b-alert>
          <b-row style="justify-content: center;">
            <b-col :cols="5" :sm="4" class="text-center"><b-button :variant="navIndex==1? 'primary': 'outline-light'" style="width: 6rem" squared @click="navchange(1)">Log In</b-button></b-col>
            <b-col :cols="5" :sm="4" class="text-center"><b-button :variant="navIndex==2? 'primary': 'outline-light'" style="width: 6rem" squared @click="navchange(2)">Sign Up</b-button></b-col>
          </b-row>
          
          <div class="form_box">
            <b-form @submit="onLogin" v-if="showLogin" ref="loginRule">
              <b-form-group v-if="navIndex === 2">
                <b-form-input v-model="loginForm.nickName" required placeholder="Name"></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-input v-model="loginForm.email" type="email" required placeholder="Email" autocomplete="new-password"></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-input type="password" v-model="loginForm.password" :state="valiPassword" required autocomplete="new-password"
                  placeholder="Password"></b-form-input>
                <b-form-invalid-feedback id="pass2" :state="valiPassword">User password length must be between 5 and 20</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group v-if="navIndex === 2">
                <b-form-input type="password" v-model="loginForm.confirmPassword" :state="validationPassword" required
                  placeholder="Confirm Password"></b-form-input>
                <b-form-invalid-feedback :state="validationPassword">
                  The two passwords are inconsistent
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group v-if="navIndex === 2">
                <b-input-group>
                  <b-form-input v-model="loginForm.code" required placeholder="Email verification code"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="info" :disabled="isSendCode" @click="sendCodeClick">Send Code</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <div class="d-flex justify-content-between align-items-center mb-2" style="font-size: 0.8rem" v-if="navIndex == 1">
                <b-form-checkbox-group v-model="loginForm.rememberMe" id="checkbox_remember" style="flex: 1;">
                  <b-form-checkbox value="true" style="color: #007BFF;"><span>Remember</span></b-form-checkbox>
                </b-form-checkbox-group>
                <b-link class="text-right" @click="forgotPwd">Forgot password?</b-link>
              </div>
              <b-button type="submit" variant="primary" block>
                <div v-if="navIndex === 1">Log In</div>
                <div v-else>Sign Up</div>
              </b-button>
            </b-form>
            <div class="p-3" style="text-align: center;">By creating your DaVinci account, you agree to the <span
                style="color: #007bff;"> Terms of Use</span> and <span style="color: #007bff;">Privacy Policy</span>.
            </div>
          </div>
        </div>

        <div class="confirm_code d-flex align-items-center" v-if="showCode">
          <div class="code_content">
            <b-alert :show="showAlert" variant="success">{{ successMsg }}</b-alert>
            <b-alert :show="showErrorAlert" variant="danger">{{ errorMsg }}</b-alert>
            <div class="text-center codetitle" style="font-size: 1.5rem;margin-bottom: 2rem;">Confirmation Code</div>
            <b-form-input v-model="loginForm.code" placeholder="Enter code"></b-form-input>
            <div class="text-center text">Please check your email for the code.</div>
            <b-row>
              <b-col :cols="12">
                <b-button variant="primary" block class="mt-3" @click="checkCodeClick">Verify Email Code</b-button>
              </b-col>
<!--              <b-col :cols="6">
                <b-button variant="primary" block class="mt-3" @click="toHome">Verify later</b-button>
              </b-col>-->
            </b-row>
            <div class="text-right mt-3">
              <!-- <b-link @click="toHome">Verify later</b-link> -->
              <b-link @click="sendCodeClick">Resend Code</b-link>
            </div>
          </div>
        </div>
        
        <div class="confirm_code d-flex align-items-center" v-if="showForgetPwd">
          <div class="code_content">
            <div class="text-center pwdtitle" style="font-size: 1.5rem;margin-bottom: 1rem;">Retrieve Password</div>
            <b-form @submit="retrievePassword">
              <b-form @submit="sendCodeClick">
                <b-form-group>
                  <b-form-input v-model="retrievePwd.email" type="email" required placeholder="Enter email"></b-form-input>
                </b-form-group>
                <b-row>
                  <b-col cols="6" sm="8">
                    <b-form-group>
                      <b-form-input v-model="retrievePwd.code" placeholder="Enter code"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6" sm="4">
                    <b-button variant="primary" block type="submit">Get code</b-button>
                  </b-col>
                </b-row>
              </b-form>
              <b-form-group>
                <b-form-input v-model="retrievePwd.password" type="password" placeholder="Enter new password" :state="valiforgotPwd" required></b-form-input>
                <b-form-invalid-feedback id="pass2" :state="valiforgotPwd">User password length must be between 5 and 20</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group>
                <b-form-input v-model="retrievePwd.confirmPassword" type="password" placeholder="Confirm Password" :state="validforgotPassword" required></b-form-input>
                <b-form-invalid-feedback :state="validforgotPassword">
                  The two passwords are inconsistent
                </b-form-invalid-feedback>
              </b-form-group>
              <div>
                <b-button block type="submit" variant="primary" :disabled="!valiforgotPwd && !validforgotPassword">Confirm</b-button>
              </div>
              <div class="text-right mt-2">
                <b-link @click="hideForgot">Login</b-link>
              </div>
            </b-form>
          </div>
        </div>
        
      </div>
    </div>

  </div>
</template>

<script>
  import Cookies from "js-cookie";
  import {
    encrypt,
    decrypt
  } from '@/utils/jsencrypt'
  import {
    register,
    sendCode,
    checkCode
  } from "@/api/login";
  import {
    findPassword
  } from "@/api/user";

  export default {
    props: {
      taglogin: {
        type: Number,
        default: 1,
      }
    },
    watch: {
      'taglogin': function(val) {
        this.navIndex = val;
        this.showCode = false;
        if (this.navIndex === 2) {
          this.loginForm = {
            email: "",
            nickName: "",
            password: "",
            confirmPassword: "",
            rememberMe: [],
            code: "",
          };
        }
        this.showAlert = false;
      },
    },
    data() {
      return {
        bgObj: {
          bg: require("@/assets/index/bg.png"),
          size: '100% 27%',
        },
        tag: 1, //1登录 2注册
        showAlert: false,
        showErrorAlert: false,
        showCode: false,
        showForgetPwd: false,
        successMsg: "",
        errorMsg: "",
        navIndex: 1,
        showLogin: true,
        isSendCode: false,
        loginForm: {
          email: "",
          nickName: "",
          password: "",
          confirmPassword: "",
          rememberMe: [],
          code: "",
          inviteCode: ""
        },
        retrievePwd: {
          code: "",
          email: "",
          password: "",
          confirmPassword: "",
        },
      }
    },
    created() {
      if (this.$route.params && this.$route.params.tag) {
        this.navIndex = this.$route.params.tag;
      }
      if(this.$route.query.scode){
        this.loginForm.inviteCode = this.$route.query.scode
        this.navIndex = 2;
      }
      if (this.navIndex === 1) {
        this.loginForm.email = Cookies.get("davinciEmail") ? Cookies.get("davinciEmail") : "";
        this.loginForm.password = decrypt(Cookies.get("davinciPassword")) ? decrypt(Cookies.get("davinciPassword")) : "";
        this.loginForm.rememberMe = Cookies.get("davinciRememberMe") ? [Cookies.get("davinciRememberMe")] : [];
      } else {
        this.loginForm.email = "";
        this.loginForm.password = "";
        this.loginForm.rememberMe = [];
      }
      this.$emit('imageBg', this.bgObj)
    },
    methods: {
      navchange(index) {
        this.navIndex = index;
        this.$emit('tagLSchange', index);
        this.showErrorAlert = false;
        this.loginForm = {
          email: "",
          password: "",
          confirmPassword: "",
          nickName: "",
          rememberMe: [],
          code: "",
          inviteCode: ""
        }
      },
      onLogin(e) {
        this.showErrorAlert = false;
        if (this.$refs['loginRule'].checkValidity()) {
          if (this.navIndex === 1 && this.valiPassword) {
            if (this.loginForm.rememberMe[0]) {
              Cookies.set("davinciEmail", this.loginForm.email, {
                expires: 30
              });
              Cookies.set("davinciPassword", encrypt(this.loginForm.password), {
                expires: 30
              });
              Cookies.set('davinciRememberMe', this.loginForm.rememberMe[0], {
                expires: 30
              });
            } else {
              Cookies.remove("davinciEmail");
              Cookies.remove("davinciPassword");
              Cookies.remove('davinciRememberMe');
            }
            this.$store.dispatch("Login", this.loginForm).then(res => {
              this.$emit('isAuthEmail' , res.authStatus)
              this.toHome();
            }).catch((msg) => {
              // console.log(msg)
              this.errorMsg = msg
              this.showErrorAlert = true;
            });
          } else if (this.navIndex === 2 && this.valiPassword && this.validationPassword) {

            let registerForm = {
              email: this.loginForm.email,
              password: this.loginForm.password,
              name: this.loginForm.nickName,
              code: this.loginForm.code,
              inviteCode: this.loginForm.inviteCode
            }
            this.$store.dispatch("Register", registerForm).then(res => {
              if(res.authStatus === 1){
                this.toHome();
              } else {
                this.successMsg = "Congratulations，your account" + this.loginForm.nickName + "has been successfully registered!";
                this.showAlert = true;
                this.showCode = true;
                // this.sendCodeClick();
                this.$emit('isAuthEmail' , res.authStatus)
              }
            }).catch((msg) => {
              this.errorMsg = msg
              // this.showErrorAlert = true;
              this.showAlert = false;
            });
          }
        }
        e.preventDefault()
        e.stopPropagation()
      },
      // 发送验证码
      sendCodeClick(e){
        let email = null;
        if( this.showForgetPwd && this.retrievePwd.email ){
          email = this.retrievePwd.email
        } else if(this.loginForm.email){
          email = this.loginForm.email
        }
        this.isSendCode = true;
        sendCode(email).then( res => {
          this.$bvToast.toast( "Verification code send successfully" ,{
            title: "Operating tips",
            variant: "success",
            toaster: 'b-toaster-top-center',
            solid: true,
            autoHideDelay: 2000
          })
        }).catch( error => {
          this.errorMsg = error;
          // this.showErrorAlert = true;
        }).finally(()=>{
          this.isSendCode = false;
        })
        if( this.showForgetPwd ){
          e.preventDefault()
          e.stopPropagation()
        }
        
      },
      //校验验证码
      checkCodeClick(){
        if( this.loginForm.code){
          checkCode(this.loginForm.code).then( res => {
            this.successMsg = "Verification successful！";
            this.showAlert = true;
            this.$emit('isAuthEmail' , 1)
            setTimeout(() => {
              this.$router.replace({
                path: "/index",
              })
            } , 2000)
          }).catch( err => {
            this.errorMsg = err;
            this.showErrorAlert = true;
          })
        }
      },
      //稍后校验
      toHome(){
        this.$router.replace({
          path: "/index"
        })
      },
      forgotPwd(){
        this.showForgetPwd = true;
      },
      hideForgot(){
        this.showForgetPwd = false;
        this.retrievePwd = {
          code: "",
          email: "",
          password: "",
          confirmPassword: "",
        }
      },
      retrievePassword(e){
        if( !this.retrievePwd.code ){
          this.$bvToast.toast( "Verification code cannot be empty" ,{
            title: "Operating tips",
            variant: "warning",
            toaster: 'b-toaster-top-center',
            solid: true,
            autoHideDelay: 2000
          })
        } else {
          findPassword({
            code: this.retrievePwd.code,
            email: this.retrievePwd.email,
            password: this.retrievePwd.password
          }).then( res => {
            this.$bvToast.toast( "Retrieved successfully" ,{
              title: "Operating tips",
              variant: "success",
              toaster: 'b-toaster-top-center',
              solid: true,
              autoHideDelay: 2000
            })
            this.loginForm.email = this.retrievePwd.email
            this.loginForm.password = this.retrievePwd.password
            this.hideForgot()
          }).catch( err => {
          })
        }
        e.preventDefault()
        e.stopPropagation()
      },
    },
    computed: {
      valiemail() {
        //用户账号长度必须介于 2 和 20 之间
        if (this.loginForm.email && this.navIndex == 2) {
          return this.loginForm.email.length >= 2 && this.loginForm.email.length <= 20
        }
      },
      validationPassword() {
        if (this.loginForm.confirmPassword) {
          return this.loginForm.password == this.loginForm.confirmPassword
        }
      },
      valiPassword() {
        //用户密码长度必须介于 5 和 20 之间
        if (this.loginForm.password) {
          return this.loginForm.password.length >= 5 && this.loginForm.password.length <= 20
        }
      },
      validforgotPassword() {
        if (this.retrievePwd.confirmPassword) {
          return this.retrievePwd.password == this.retrievePwd.confirmPassword
        }
      },
      valiforgotPwd() {
        //用户密码长度必须介于 5 和 20 之间
        if (this.retrievePwd.password) {
          return this.retrievePwd.password.length >= 5 && this.retrievePwd.password.length <= 20
        }
      },

    }
  }
</script>

<style lang="scss">
  .login_container {
    // padding: 50px;

    .left_box {
      height: 100%;
    }

    .right_box {
      padding: 20px 0;

      .nav-link {
        font-weight: bolder;
        color: #888;
        font-size: 1.4rem;

        &.active {
          color: #0667d0;
        }
      }

      .nav-link:hover {
        background-color: #f6f6f7;
        color: #333;
      }

      .form_box {
        padding-top: 3rem;
      }
      
      .btn-primary {
        background-color: $btn-color;
        border-color: $btn-color;
      
        &:hover {
          background-color: $theme;
          border-color: $theme;
        }
      }
      .btn-outline-light {
        border: 0;
        color: #fff;
      
        &:hover {
          background-color: #fff0;
        }
      }
    }

    .confirm_code {
      height: 100%;

      .code_content {
        width: 100%;
      }

      .title {
        color: #8e8e8e;
      }

      .text {
        margin: 0.5rem 0 1rem;
        color: #6c757d;
        font-size: 0.8rem;
      }
    }
    
    #checkbox_remember {
      
      .custom-control-label {
        display: flex;
        align-items: center;
      }
      
    }
    
    @media  screen and (max-width: 1200px) {
      .pwdtitle {
        color: #fff;
      }
    }
    
    @media  screen and (max-width: 992px) {
      .codetitle {
        color: #fff;
      }
    }
  }
  
  
</style>
